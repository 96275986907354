// extracted by mini-css-extract-plugin
export var project = "Project-module--project--3JCQy";
export var header = "Project-module--header--3h8Ok";
export var container = "Project-module--container--2WLvC";
export var button = "Project-module--button--1Qa3T";
export var icon = "Project-module--icon--N7tzO";
export var background = "Project-module--background--13VI5";
export var foreground = "Project-module--foreground--3n4tY";
export var heading = "Project-module--heading--3uifY";
export var blocks = "Project-module--blocks--2LLXY";
export var block = "Project-module--block--2Bo9u";
export var title = "Project-module--title--27U-o";
export var customer = "Project-module--customer--3Nyn8";
export var service = "Project-module--service--1kT-3";
export var topImage = "Project-module--topImage--3gges";
export var gutenberg = "Project-module--gutenberg--3HRL6";
export var buttons = "Project-module--buttons--3pAsO";
export var prevArrowButton = "Project-module--prevArrowButton--1gTfl";
export var nextArrowButton = "Project-module--nextArrowButton--6J5cx";