import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type ArrowRightIconProps
 */
export type ArrowRightIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const ArrowRightIcon
 */
const ArrowRightIcon = (props: ArrowRightIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="7.66" ref={ ref } viewBox="0 0 17.841 7.66" width="17.841">
            <path d="M 14.011719 0 L 13.021484 0.99023438 L 15.199219 3.1679688 L 0 3.1679688 L 0 4.5683594 L 15.123047 4.5683594 L 13.021484 6.6699219 L 14.011719 7.6601562 L 17.841797 3.8300781 L 14.011719 0 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(ArrowRightIcon);
