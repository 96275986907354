import React from "react";

// @ts-ignore
import TransitionLink from "gatsby-plugin-transition-link";

import { GatsbyImage } from "gatsby-plugin-image";

import { Spring } from "react-spring";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
import { Ref } from "react";

// @ts-ignore
import { TransitionState } from "gatsby-plugin-transition-link";

import { animated } from "react-spring";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import ArrowLeftIcon from "@Js/Component/Icon/ArrowLeftIcon";
import AnchorButton from "@Js/Component/Button/AnchorButton";
import ArrowRightIcon from "@Js/Component/Icon/ArrowRightIcon";
import Gutenberg from "@Js/Component/Gutenberg";
import Heading from "@Js/Component/Heading";
import LinkButton from "@Js/Component/Button/LinkButton";
import Service from "@Js/Type/Service";
import WhatWeCanDoForYouBlock from "@Js/Component/Block/WhatWeCanDoForYouBlock";

import * as classNames from "@Css/Component/Page/Project.module.scss";

/**
 * @type TransitionStateProps
 */
type TransitionStateProps = {
    current: {
        state: {
            direction: "left" | "right";
        };
    };
    mount: boolean;
    transitionStatus: "entering" | "entered" | "exiting" | "exited";
};

/**
 * @type WordpressProjectsPageData
 */
type WordpressProjectsPageData = {
    wordpressPage: {
        uri: string;
    };
};

/**
 * @type ProjectProps
 */
export type ProjectProps = PageProps & {
    pageContext: {
        nextProject?: {
            uri: string;
        };
        previousProject?: {
            uri: string;
        };
        project: {
            content?: string;
            details: {
                image: {
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
                primaryColor: string;
                services: Service[];
                title: string;
                url: string;
            };
            featuredImage?: {
                node: {
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
            };
            id: string;
            seo: {
                canonical: string;
                metaDesc: string;
                metaKeywords: string;
                metaRobotsNofollow: string;
                metaRobotsNoindex: string;
                opengraphAuthor: string;
                opengraphDescription: string;
                opengraphModifiedTime: string;
                opengraphPublishedTime: string;
                opengraphPublisher: string;
                opengraphSiteName: string;
                opengraphTitle: string;
                opengraphType: string;
                opengraphUrl: string;
                schema: {
                    raw: string;
                };
                title: string;
            };
            title: string;
        };
    };
};

/**
 * @const Project
 */
const Project = (props: ProjectProps, ref: Ref<HTMLElement>) => {
    const {
        pageContext
    } = props;

    const { t } = useTranslation();

    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const data: WordpressProjectsPageData = useStaticQuery(graphql`
        query {
            wordpressPage(isProjectsPage: { eq: true }) {
                uri
            }
        }
    `);

    const getService = (service: Service) => {
        switch (service) {
            case "e_commerce":
                return t("E-commerce");

            case "web_application":
                return t("Web Application");

            case "website":
                return t("Website");
        }
    };

    useLayoutEffect(() => {
        setHeaderBackground(pageContext.project.details.primaryColor);

        setIsLogoColorless(true);
        setIsMenuColorless(true);
    }, []);

    return (
        <>
            <Seo { ...pageContext.project.seo } />
            <TransitionState>
                { (props: TransitionStateProps) => {
                    const {
                        current,
                        mount,
                        transitionStatus
                    } = props;

                    const config = {
                        duration: transitionStatus == "entered" ? 0 : 250,

                        easing: (t: number) => {
                            return -(Math.cos(Math.PI * t) - 1) / 2;
                        }
                    };

                    let from = { transform: "translateX(100vw)" };
                    let to = { transform: mount ? "translateX(0vw)" : "translateX(-100vw)" };

                    if (current.state.direction == "right") {
                        from = { transform: "translateX(-100vw)" };
                        to = { transform: mount ? "translateX(0vw)" : "translateX(100vw)" };
                    }

                    const id = `project-${ pageContext.project.id.replace(/\W/g, "") }`;

                    const element = (
                        <div className={ classNames.project } id={ id }>
                            <style>
                                { `#${ id } { --theme-color: ${ pageContext.project.details.primaryColor }; }` }
                            </style>
                            <header className={ classNames.header }>
                                <div className={ classNames.container }>
                                    <LinkButton
                                        backgroundProps={{ className: classNames.background }}
                                        className={ classNames.button }
                                        color="secondary"
                                        foregroundProps={{ className: classNames.foreground }}
                                        to={ data.wordpressPage.uri }
                                        variant="outlined"
                                    >
                                        <ArrowLeftIcon className={ classNames.icon } />
                                        <span>{ t("To overview") }</span>
                                    </LinkButton>
                                    <Heading className={ classNames.heading }>
                                        { pageContext.project.details.title }
                                    </Heading>
                                    <div className={ classNames.blocks }>
                                        <div className={ classNames.block }>
                                            <strong className={ classNames.title }>{ t("Customer") }</strong>
                                            <span className={ classNames.customer }>{ pageContext.project.title }</span>
                                        </div>
                                        <div className={ classNames.block }>
                                            <strong className={ classNames.title }>{ t("Service") }</strong>
                                            <span className={ classNames.service }>
                                                { pageContext.project.details.services.map((service) => {
                                                    return getService(service)
                                                }).join(", ") }
                                            </span>
                                        </div>
                                        { pageContext.project.details.url && (
                                            <div className={ classNames.block }>
                                                <AnchorButton
                                                    className={ classNames.button }
                                                    color="secondary"
                                                    href={ pageContext.project.details.url }
                                                    target="_blank"
                                                >
                                                    <span>{ t("View online") }</span>
                                                    <ArrowRightIcon className={ classNames.icon } />
                                                </AnchorButton>
                                            </div>
                                        ) }
                                    </div>
                                </div>
                            </header>
                            { pageContext.project.details.image && (
                                <div className={ classNames.topImage }>
                                    <GatsbyImage
                                        alt={ pageContext.project.title }
                                        image={ pageContext.project.details.image.localFile.childImageSharp.gatsbyImageData }
                                    />
                                </div>
                            ) }
                            { pageContext.project.content && (
                                <Gutenberg className={ classNames.gutenberg } content={ pageContext.project.content } />
                            ) }
                            <WhatWeCanDoForYouBlock />
                        </div>
                    );

                    return (
                        <section className={ classNames.project } ref={ ref }>
                            <Spring config={ config } from={ from } to={ to }>
                                { (style) => (
                                    <animated.div style={ style }>
                                        { element }
                                    </animated.div>
                                ) }
                            </Spring>
                            <div className={ classNames.buttons }>
                                { pageContext.previousProject && (
                                    <TransitionLink
                                        aria-label={ t("Previous") }
                                        className={ classNames.prevArrowButton }
                                        entry={{ delay: 0, state: { direction: "right" } }}
                                        exit={{ length: 0.25, state: { direction: "right" } }}
                                        to={ pageContext.previousProject.uri }
                                    >
                                        <ArrowLeftIcon className={ classNames.icon } />
                                    </TransitionLink>
                                ) }
                                { !(pageContext.previousProject) && (
                                    <button className={ classNames.prevArrowButton } disabled>
                                        <ArrowLeftIcon className={ classNames.icon } />
                                    </button>
                                ) }
                                { pageContext.nextProject && (
                                    <TransitionLink
                                        aria-label={ t("Next") }
                                        className={ classNames.nextArrowButton }
                                        entry={{ delay: 0, state: { direction: "left" } }}
                                        exit={{ length: 0.25, state: { direction: "left" } }}
                                        to={ pageContext.nextProject.uri }
                                    >
                                        <ArrowRightIcon className={ classNames.icon } />
                                    </TransitionLink>
                                ) }
                                { !(pageContext.nextProject) && (
                                    <button className={ classNames.nextArrowButton } disabled>
                                        <ArrowRightIcon className={ classNames.icon } />
                                    </button>
                                ) }
                            </div>
                        </section>
                    );
                } }
            </TransitionState>
        </>
    );
};

export default forwardRef(Project);
