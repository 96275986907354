import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type ArrowLeftIconProps
 */
export type ArrowLeftIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const ArrowLeftIcon
 */
const ArrowLeftIcon = (props: ArrowLeftIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="7.66" ref={ ref } viewBox="0 0 17.841 7.66" width="17.841">
            <path d="M 3.8300781 0 L 0 3.8300781 L 3.8300781 7.6601562 L 4.8203125 6.6699219 L 2.640625 4.4921875 L 17.841797 4.4921875 L 17.841797 3.0917969 L 2.7167969 3.0917969 L 4.8203125 0.99023438 L 3.8300781 0 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(ArrowLeftIcon);
